'use-strict';

const Protocol = require('./protocol');
const pop = require('./population-stats');
const config = require('../data/cerebral-config.json');
const dices = require('../data/dice-configs.json');
const { ratio, clamp } = require('@hints/utils/math');
const { normalize0100, normalizeInverse0100, average, weightedAverage } = require('../utils/math');
const { isset } = require('@hints/utils/data');

class CerebralProtocol extends Protocol {
    constructor() {
        super();
        this.attentionSets = _generateTestSets(config.attention, c => this.attentionSet(c));
        this.comparisonSets = _generateTestSets(config.comparison, c => this.comparisonSet(c));
        this.trackingSets = _generateTestSets(config.tracking, c => this.trackingSet(c));
    }

    getScore(gender, age, data) {
        const { score: attentionScore, accuracy: attentionAccuracy, responseTime: attentionResponseTime } = this.getAttentionScore(gender, age, data.attention || []);
        const { score: comparisonScore, accuracy: comparisonAccuracy, responseTime: comparisonResponseTime } = this.getComparisonScore(gender, age, data.comparison || []);
        const { score: trackingScore, time: trackingTime, deviation: trackingDeviation } = this.getTrackingScore(gender, age, data.tracking || []);
        return {
            data: {
                score: average([attentionScore, comparisonScore, trackingScore]),
                attentionScore,
                comparisonScore,
                trackingScore,
                result: {
                    attentionAccuracy,
                    attentionResponseTime,
                    comparisonAccuracy,
                    comparisonResponseTime,
                    trackingTime,
                    trackingDeviation,
                }
            },
            logs: data
        };
    }
    
    validateResults(body) {
        if(!super.validateResults(body)) return false;
        const { attentionScore, comparisonScore, trackingScore, result } = body;
        if([attentionScore, comparisonScore, trackingScore, result].some(v => !isset(v))) return false;
        const { attentionAccuracy, attentionResponseTime, comparisonAccuracy, comparisonResponseTime, trackingTime} = result;
        if([attentionAccuracy, attentionResponseTime, comparisonAccuracy, comparisonResponseTime, trackingTime].some(v => !isset(v))) return false;
        return true;
    }

    /**
     * 
     * @param {*} gender 
     * @param {*} age 
     * @param {any[]} attentionResults 
     */
    getAttentionScore(gender, age, attentionResults) {
        const { mean, sd } = pop.attentionResponseTime.getData(gender, age);
        const accuracy = ratio(attentionResults.filter(f => f.correct).length, attentionResults.length) * 100;
        const responseTime = average(attentionResults.map(r => r.responseTime));
        const accuracyScore = clamp(Math.round((accuracy - 50) * 2), 0, 100);
        const responseTimeScore = normalizeInverse0100(((responseTime - mean) / sd), config.attention.zMin, config.attention.zMax);
        const score = weightedAverage(
            [accuracyScore, responseTimeScore],
            [config.attention.accuracyCoeff, config.attention.responseTimeCoeff]
        );

        return { accuracy, responseTime, score };
    }

    getComparisonScore(gender, age, comparisonResults) {
        const { mean, sd } = pop.comparisonResponseTime.getData(gender, age);
        const accuracy = ratio(comparisonResults.filter(f => f.correct).length, comparisonResults.length) * 100;
        const responseTime = average(comparisonResults.map(r => r.responseTime));
        const accuracyScore = clamp(Math.round((accuracy - 50) * 2), 0, 100);
        const responseTimeScore = normalizeInverse0100((responseTime - mean) / sd, config.comparison.zMin, config.comparison.zMax);
        const score = weightedAverage([accuracyScore, responseTimeScore], [config.comparison.accuracyCoeff, config.comparison.responseTimeCoeff]);

        return { accuracy, responseTime, score };
    }

    getTrackingScore(gender, age, trackingResults) {
        const { mean, sd } = pop.trackingTime.getData(gender, age);
        const time = average(trackingResults.map(f => ratio(f.time, f.duration))) * 100;
        const deviation = average(trackingResults.map(f => f.deviation));
        const score =  normalize0100((time - mean) / sd, config.tracking.zMin, config.tracking.zMax);
        return { time, score, deviation };
    }

    attentionSet(config) {
        const { nbCongruent = 0, nbIncongruent = 0 } = config; 
        return [
            ..._createArray(Math.floor(nbCongruent / 2),    () => ({ congruent: true, color: 'red', position: 'left' })),
            ..._createArray(Math.floor(nbCongruent / 2),    () => ({ congruent: true, color: 'blue', position: 'right' })),
            ..._createArray(Math.floor(nbIncongruent / 2),  () => ({ congruent: false, color: 'red', position: 'right' })),
            ..._createArray(Math.floor(nbIncongruent / 2),  () => ({ congruent: false, color: 'blue', position: 'left' }))
        ];
    }

    comparisonSet(config) {
        const { nbSame = 0, nbDifferent = 0 } = config; 
        const sameDices = dices.same.slice(0, nbSame); 
        const differentDices = dices.different.slice(0, nbDifferent);
        return [
            ...sameDices.map(([left, right]) => ({ same: left == right, left, right })),
            ...differentDices.map(([left, right]) => ({ same: left == right, left, right })),
        ];
    }

    trackingSet(config) {
        return ({ ...config }); // Keep things the same :D
    } 
}

function _generateTestSets(setsConfig, generator) {
    return ['real', 'training'].reduce((map, mode) => {
        if(isset(setsConfig[mode])) map[mode] = generator(setsConfig[mode]);
        return map;
    }, {});
}

/**
 * @template T
 * @param {*} length 
 * @param {(index: number) => T} itemFactory 
 * @returns {T[]}
 */
function _createArray(length, itemFactory) {
    return new Array(length).fill(0).map((_, i) => itemFactory(i));
}

module.exports = new CerebralProtocol();