'use-strict';

const Protocol = require('./protocol');
const pop = require('./population-stats');
const config = require('../data/mental-config.json');
const { atan2Deg, average, normalize0100 } = require('../utils/math');
const gaze = require('../utils/gaze');
const { round } = require('@hints/utils/math');
const { isset, asArray } = require('@hints/utils/data');

/**
 * @typedef {Object} MentalRecordPOG
 * @property {number} x
 * @property {number} y
 * @property {boolean} valid
 */

/**
 * @typedef {Object} MentalRecord
 * @property {number} count
 * @property {number} time
 * @property {{
 *  left: MentalRecordPOG,
 *  right: MentalRecordPOG,
 *  best: MentalRecordPOG,
 * }} pog
 */

class MentalProtocol extends Protocol {
    constructor() {
        super();
        this.testSet = asArray(config.guidedSaccade).map(_formatTestItem);
        this.pxToDeg = round(atan2Deg(Math.round(config.screenSizeMm[1] / 2), config.viewingDistanceMm) / (Math.round(config.screenSizePx[1] / 2)), 4);
        this.degToPx = round(1 / this.pxToDeg, 4);
        this.areaSizeDeg = config.areaSizeDeg;
        this.fixationDisplayTimeMs = config.fixationDisplayTimeMs;
        this.targetDisplayTimeMs = config.targetDisplayTimeMs;
        this.blankTimeMs = config.blankTimeMs;
    }

    /**
     * 
     * @param {'mr' | 'ms'} gender 
     * @param {number} age 
     * @param {MentalRecord[]} records 
     */
    getScore(gender, age, records) {
        const { mean, sd } = pop.mentalAvgSaccAmp.getData(gender, age);
        const validRecords = records.filter(p => isset(p.pog) && isset(p.pog.best) && p.pog.best.valid);
        const positions = validRecords.map(d => this.getRecordAngle(d));
        const velocities = gaze.vecvel(positions, config.frequencyHz);
        const { vmax = 0, vmean = 0 } = this.computePeaks(positions, velocities);
        const score = normalize0100((vmean - mean) / sd, config.zMin, config.zMax);
        return {
            logs: records,
            data: {
                score,
                result: { vmax, vmean }
            }
        };
    }
    
    validateResults(body) {
        if(!super.validateResults(body)) return false;
        const { result } = body;
        if(!isset(result)) return false;
        // if(!isset(result.vmax)) return false;
        // if(!isset(result.vmean)) return false;
        return true;
    }

    /**
     * 
     * @param {MentalRecord} data 
     */
    getRecordAngle(data) {
        const { x, y } = data.pog.best;
        const xAngle = x * config.screenSizePx[0] * this.pxToDeg;
        const yAngle = y * config.screenSizePx[1] * this.pxToDeg;
        return [xAngle, yAngle];
    } 

    /**
     * 
     * @param {[number, number][]} positions 
     * @param {[number, number][]} velocities 
     */
    computePeaks(positions, velocities) {
        const { saccades } = gaze.microsacc(positions, velocities, config.relativeVelocityThreshold, config.minSaccadeDuration);
        const minSaccadeInterval = 0.02 / (1 / config.frequencyHz);
        const validSaccades =  saccades.filter(s => ((s.end - s.onset) >= minSaccadeInterval && s.vpeak <= config.maxSaccadicVelocityDeg));
        const vmean = average(validSaccades.filter(s => s.ah > 15 && s.ah <= 20).map(s => s.vpeak)) || 0;
        const vmax = Math.max(validSaccades.map(p => p.vpeak));
        return { vmean, vmax };
    }
}

/**
 * 
 * @param {[keyof typeof config.positions, string, number]} item 
 * @returns {
 *  fixation: [number, number],
 *  offset: [number, number],
 * }
 */
function _formatTestItem(item) {
    const [position, direction, distance] = item;
    const locationConfig = config.positions[position];
    const directionConfig = locationConfig.offsets[direction] || [0, 0];
    const offsetLength = Math.hypot(directionConfig[0], directionConfig[1]);
    const offsetNormalized = [(directionConfig[0] / offsetLength), (directionConfig[1] / offsetLength)];
    return {
        fixation: locationConfig.position,
        offset: [offsetNormalized[0] * distance, offsetNormalized[1] * distance],
    };
}

module.exports = new MentalProtocol();